<template>
  <transition name="fade-in">
    <div v-if="showBanner && settings" class="app-cookie-consent__wrapper">
      <div class="app-cookie-consent__wrapper__overlay" />
      <div class="app-cookie-consent">
        <div class="app-cookie-consent__texts">
          <div class="app-cookie-consent__text">
            {{ settings.cookie_consent_text }}
            <nuxt-link
              :to="`../${settings.cookie_consent_policy_link?.cached_url}`"
              class="app-cookie-consent__link"
            >
              {{ settings.cookie_consent_policy_link_text }}
            </nuxt-link>
          </div>
          <div class="app-cookie-consent__description">
            {{ settings.cookie_consent_description }}
          </div>
        </div>
        <div class="app-cookie-consent__buttons">
          <button
            class="app-cookie-consent__button"
            type="button"
            @click="setUsersChoice(false)"
          >
            {{ settings.cookie_consent_button_required_text }}
          </button>
          <button
            class="app-cookie-consent__button"
            type="button"
            @click="setUsersChoice(true)"
          >
            {{ settings.cookie_consent_button_all_text }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
/*
  Settings and Markup Data
*/
const settings = computed(() => getStoryblokSetting('tracking'));
const showBanner = ref(false);

/*
  Composables
*/
const { init: initGtm } = useGTM();
const route = useRoute();
const excludedRoutes = settings.value?.cookie_consent_excluded_links?.map((l) => `/${l?.link?.cached_url}`);

/*
  Check for existing cookies and
  start tracking or show banner
*/
onMounted(() => {
    const { public: publicConfig } = useRuntimeConfig();
    const acceptedAll = getCookie('cookies-accepted-all');
    const acceptedRequired = getCookie('cookies-accepted-required');

    if (acceptedAll && publicConfig.USE_GA === 'true') {
        initGtm();
    }

    if (!acceptedAll && !acceptedRequired && !excludedRoutes.includes(route.path)) {
        showBanner.value = true;
    }
});

/*
  set cookies and hide banner and start tracking (if allowed)
*/
const setUsersChoice = (hasAll) => {
    showBanner.value = false;

    if (hasAll) {
        initGtm();
        setCookie('cookies-accepted-all', true, 6);
        return;
    }

    setCookie('cookies-accepted-required', true, 6);
};

watch(() => route.path, (newVal) => {
    const acceptedAll = getCookie('cookies-accepted-all');
    const acceptedRequired = getCookie('cookies-accepted-required');
    if (acceptedAll || acceptedRequired) {
        return;
    }
    showBanner.value = !excludedRoutes.includes(newVal);
});
</script>
<style lang="scss">
.app-cookie-consent__wrapper {
    @include z-index('cookieOverlay');
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
}

.app-cookie-consent__wrapper__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    background: rgba(255, 255, 255, 0.5);
}

.app-cookie-consent {
    @include typo-size('p');
    @include typo-font('regular');
    @include z-index('cookie');

    position: absolute;
    top: 50%;
    left: 50%;
    overflow: hidden;
    width: 100%;
    max-width: 628px;
    border-radius: 12px;
    background: $C_WHITE;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
    text-align: center;
    transform: translate(-50%, -50%);

    @include mobile {
        max-width: calc(100% - (var(--page-padding) * 2));
    }
}

.app-cookie-consent__description {
    @include typo-font('regular');
    @include typo-size('p');
    @include fluid('line-height', 24px, 26px);
    @include fluid('font-size', 14px, 18px);

    color: $C_GREEN_DARK;
    text-align: left;
}

.app-cookie-consent__texts {
    display: grid;
    padding: 34px 33px;
    gap: 20px;
    text-align: left;
}

.app-cookie-consent__text {
    @include typo-font('light');
    @include fluid('font-size', 20px, 26px);
    color: $C_GREEN_DARK;
    line-height: 32px;
}

.app-cookie-consent__title {
    @include typo-font('bold');
}

.app-cookie-consent__buttons {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid $C_GREY_LINE_LIGHT;
}

.app-cookie-consent__button {
    @include fluid('height', 56px, 56px);
    @include fluid('line-height', 56px, 56px);
    @include typo-font('regular');

    width: 50%;
    cursor: pointer;
    font-size: 16px;

    &:first-child {
        color: $C_GREEN_OLIVE;
    }

    &:last-child {
        background: $C_GREEN_OLIVE_MEDIUM;
        color: $C_WHITE;
    }
}

.app-cookie-consent__link {
    color: $C_GREEN_OLIVE_MEDIUM;
}
</style>
